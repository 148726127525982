type Stage = 'dev' | 'prod';

type SentryConfig = { enabled: true; dsn: string } | { enabled: false };
interface AppConfiguration {
  stage: Stage;
  api: {
    baseUrl: string;
  };
  sentry: SentryConfig;
}

const devConfig: AppConfiguration = {
  stage: 'dev',
  api: {
    baseUrl: 'http://localhost:8000',
  },
  sentry: {
    enabled: false,
  },
};

const prodConfig: AppConfiguration = {
  stage: 'prod',
  api: {
    baseUrl: 'https://homework-fyos.onrender.com',
  },
  sentry: {
    enabled: true,
    dsn: 'https://d848ef25af3797cbb59da27930d8f5cd@o4506636291997696.ingest.sentry.io/4506636294356992',
  },
};

const config = import.meta.env.PROD ? prodConfig : devConfig;

export default config;
